import React from "react"

import Container from "../../../components/container/container"
import Section from "../../../components/section/section"
import TextBlock from "../../../components/text-block/text-block"

import Photoshop from "../../../images/website/adobe/new-style/photoshop.svg"
import Illustrator from "../../../images/website/adobe/new-style/illustrator.svg"
import Xd from "../../../images/website/adobe/new-style/xd.svg"
import Indesign from "../../../images/website/adobe/new-style/indesign.svg"

const GraphicsLandingBlock = ({ darkBackground, backgroundColor }) => (
    <Section
        id="graphics-landing"
        darkBackground={darkBackground}
        style={{ backgroundColor }}
    >
        <Container type="default">
            <div className="graphics-landing-content">
                <TextBlock
                    title="Graphic design."
                    subtitle="Een visueel aantrekkelijk design doet wonderen."
                    size="big"
                />
                
                <div className="graphics-landing-tools">
                    Wij werken met
                    <div className="graphics-landing-tools-icons">
                        <img src={Photoshop} alt="Adobe Photoshop" />
                        <img src={Illustrator} alt="Adobe Illustrator" />
                        <img src={Xd} alt="Adobe XD" />
                        <img src={Indesign} alt="Adobe InDesign" />
                    </div>
                    
                </div>
            </div>
        </Container>
        <div className="graphics-landing-background">

        </div>
    </Section>
)

export default GraphicsLandingBlock
