import { Link } from "gatsby"
import React from "react"

import Container from "../../../components/container/container"
import Icon from "../../../components/icon/icon"
import Section from "../../../components/section/section"
import TextBlock from "../../../components/text-block/text-block"

import { RiMarkupLine, RiProfileLine, RiAccountBoxLine, RiFile3Line, RiBookOpenLine, RiMailLine, RiQuestionLine, RiFingerprint2Line } from 'react-icons/ri';

const GraphicsServicesBlock = ({ darkBackground, backgroundColor }) => (
    <Section
        id="graphics-services"
        darkBackground={darkBackground}
        style={{ backgroundColor }}
    >
        <Container type="wide">
            <div className="graphics-services-content">
                <TextBlock
                    title="Grafische diensten."
                >
                    {/* Het maken van een goede eerste indruk is erg belangrijk. Vergroot je zichtbaarheid en laat jezelf opvallen bij uw doelgroep. */}
                </TextBlock>
                <div className="graphics-services-grid">
                    <div className="graphics-service graphics-service-1" id="logo">
                        <div className="service-header">
                            <div className="service-icon">
                                <Icon icon={<RiMarkupLine />} />
                            </div>
                            <div className="service-title"><h3>Logo </h3></div>
                        </div>
                        <div className="service-description">
                            <p>Logo's zorgen voor herkenbaarheid. Bij digitam ontwerpen wij logo's die passen bij de uistraling van uw merk of bedrijf. Hiermee maakt u een goede eerste indruk.</p> <br /> <p>Wij ontwerpen geheel nieuwe logo's en moderniseren bestaande logo's. Alle logo's worden geleverd als <strong>.ai</strong> en <strong>.svg</strong> bestanden.</p></div>
                    </div>
                    <div className="graphics-service graphics-service-2" id="branding-huisstijl">
                        <div className="service-header">
                            <div className="service-icon">
                                <Icon icon={<RiFingerprint2Line />} />
                            </div>
                            <div className="service-title"><h3>Branding &#38; Huisstijl </h3></div>
                        </div>
                        <div className="service-description">
                            <p> Een huisstijl zorgt voor één herkenbare stijl waarmee een merk of bedrijf zich presenteert. Denk hierbij aan een herkenbaar logo, een bijpassende website, flyers, brochures en visitekaartjes, maar ook herkenbare advertenties voor bijvoorbeeld op Instagram of Facebook.</p><br /> <p>Wij creëren een visuele identiteit, die past bij uw bedrijf en waarmee u zich kan onderscheiden van de concurrentie. Hiermee versterkt u uw merk en daarmee ook uw bedrijf.</p></div>
                    </div>
                    <div className="graphics-service graphics-service-3" id="poster-flyer">
                        <div className="service-header">
                            <div className="service-icon">
                                <Icon icon={<RiProfileLine />} />
                            </div>
                            <div className="service-title"><h3>Poster &#38; Flyer </h3></div>
                        </div>
                        <div className="service-description">
                            <p>Met posters en flyers geeft u op een aantrekkelijke manier informatie. Het is ideaal om evenementen of aanbiedingen ermee te promoten, maar kan ook gebruikt worden voor een informatiever doeleinde, bijvoorbeeld een infographic.</p><br /> <p>Wij zorgen voor een opvallende poster, op maat gemaakt volgens uw wensen, die uw doelgroep bereikt. U levert de tekst, wij doen de rest!</p></div>
                    </div>
                    <div className="graphics-service graphics-service-4" id="visitekaartje">
                        <div className="service-header">
                            <div className="service-icon">
                                <Icon icon={<RiAccountBoxLine />} />
                            </div>
                            <div className="service-title"><h3>Visitekaartje </h3></div>
                        </div>
                        <div className="service-description">
                            <p>Anno {new Date().getFullYear()} is het delen van gegevens zeer eenvoudig. Merken en bedrijven zijn snel vindbaar op het internet. Toch is bewezen dat het geven van visitekaartjes nog steeds de snelste manier is om uw gegevens te delen.</p>
                            <br /> <p>Wij ontwerpen visitekaartjes met 3 achterliggende gedachten. Een visitekaartje moet overzichtelijk zijn, legitimiteit geven aan je merk of bedrijf en moet gebruikt kunnen worden als promotiemateriaal.</p></div>
                    </div>
                    <div className="graphics-service graphics-service-5" id="brochure-folder">
                        <div className="service-header">
                            <div className="service-icon">
                                <Icon icon={<RiBookOpenLine />} />
                            </div>
                            <div className="service-title"><h3>Brochure  &#38; Folder </h3></div>
                        </div>
                        <div className="service-description">
                            <p>Wilt u snel een grote groep mensen bereiken? Dan zijn brochures en folders geschikt. Ze beschikken over veel ruimte, waardoor u veel informatie kwijt kunt om uw doelgroep te overtuigen. Denk bijvoorbeeld aan een folder met al de producten die u verkoopt, of een brochure met bedrijfsinformatie.</p> <br /> <p>Wij zorgen voor een opvallende brochure of folder, op maat gemaakt volgens uw wensen, die uw doelgroep bereikt. U levert de tekst, wij doen de rest!</p></div>
                    </div>
                    <div className="graphics-service graphics-service-6" id="kaartje">
                        <div className="service-header">
                            <div className="service-icon">
                                <Icon icon={<RiMailLine />} />
                            </div>
                            <div className="service-title"><h3>Kaartje </h3></div>
                        </div>
                        <div className="service-description">
                            <p>Bij digitam ontwerpen wij niet alleen visitekaartjes. Ook voor normale kaartjes kunt u bij ons terecht. Gaat u een feest geven, iets bijzonders bekendmaken, of wilt u simpelweg iemand iets persoonlijks laten weten, en wilt u uw gasten met een bijpassend vormgegeven kaartje uitnodigen? Dan is een gepersonaliseerd kaartje de juiste keuze.</p> <br /> <p>Wij ontwerpen gepersonaliseerde kaartjes volledig naar wens. Wij leveren de kaartjes in verschillende bestandstypen, waaronder <strong>.ai</strong> zodat u in de toekomst gemakkelijk het kaartje kan aanpassen en kan hergebruiken!</p></div>
                    </div>
                    <div className="graphics-service graphics-service-7" id="briefpapier">
                        <div className="service-header">
                            <div className="service-icon">
                                <Icon icon={<RiFile3Line />} />
                            </div>
                            <div className="service-title"><h3>Briefpapier </h3></div>
                        </div>
                        <div className="service-description">
                            <p>legitimiteit geven aan uw merk of bedrijf kan gemakkelijk met briefpapier. Dit kan voor verschillende doeleinden worden gebruikt, bijvoorbeeld voor facturen, rekeningen, herinneringen en algemene communicatie.</p> <br /> <p>Wij ontwerpen briefpapier dat past bij uw huisstijl. Wij leveren het briefpapier in verschillende bestandstypen, waaronder <strong>.ai</strong>, waardoor u in de toekomst het ontwerp eenvoudig aan kan passen, en <strong>.docx</strong>, waardoor u digitaal het briefpapier kunt vullen in een programma zoals <strong>Microsoft Word</strong>.</p></div>
                    </div>
                    <div className="graphics-service graphics-service-8" id="speciale-wensen">
                        <div className="service-header">
                            <div className="service-icon">
                                <Icon icon={<RiQuestionLine />} />
                            </div>
                            <div className="service-title"><h3>Speciale wensen? </h3></div>
                        </div>
                        <div className="service-description">
                            <p>Heeft u speciale wensen? Geen probleem, digitam staat voor u klaar. Neem contact met ons op voor meer informatie, geheel vrijblijvend. Contact opnemen kan door een email te sturen, of het formulier in te vullen.</p> <br /> <p>Bent u een <strong>non-profitorganisatie</strong>, of een <strong>goed doel</strong>? Neem contact met ons op voor een speciale service. </p>
                            <div className="service-link">
                                <Link to="/contact">Neem contact op</Link>
                        </div></div>
                        
                    </div>
                </div>

            </div>
        </Container>
    </Section>
)

export default GraphicsServicesBlock
