import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ContactBlock from "../blocks/general-blocks/contact-block/contact-block"

import GraphicsLandingBlock from "../blocks/graphics-blocks/graphics-landing-block/graphics-landing-block"
import GraphicsServicesBlock from "../blocks/graphics-blocks/graphics-services-block/graphic-services-block"


import { colors } from "../imports/colors"

const DesignPage = () => (
    <Layout>
        <SEO 
            title="Graphic design"
            description="Op zoek naar een logo, een visitekaartje of gehele huisstijl? Bij digitam maken we geweldige designs, voor een leuke prijs!"
            lang="nl" 
            cannonicalUrl="https://digitam.nl/graphic-design"
        />
        <GraphicsLandingBlock darkBackground={false} backgroundColor={colors["grey-00"]} />
        <GraphicsServicesBlock darkBackground={false} backgroundColor={colors["grey-00"]} />
        <ContactBlock darkBackground={true} backgroundColor={colors["blue-05"]}>
            Neem contact met ons op voor meer informatie, geheel vrijblijvend. <br />
            Contact opnemen kan door een email te sturen, of het formulier in te vullen.
        </ContactBlock>
        
    </Layout>
)

export default DesignPage
